<template>
	<v-card
		:elevation="24"
		class="mb-4"
		:loading="loading">
		<div class="draggable-handle d-flex justify-center cursor-grap">
			<v-icon>
				mdi-drag-horizontal
			</v-icon>
		</div>
		<v-card-text>
			<v-chip
				x-small>
				{{ question.order }}
			</v-chip>
			<v-textarea
				v-model="question.text"
				label="Питання"
				rows="1"
				hide-details="auto"
				auto-grow
				@input="debUpdateQuestion">
			</v-textarea>

			<ImageCard
				v-for="(image, key) in images"
				:key="key"
				:image="image"
				@delete="deleteImage">
			</ImageCard>

			<v-btn
				class="my-2"
				icon
				@click="showUploadZone = !showUploadZone">
				<v-icon>mdi-image-plus</v-icon>
			</v-btn>

			<v-expand-transition>
				<FileUpload
					v-if="showUploadZone"
					@upload="addImage">
				</FileUpload>
			</v-expand-transition>
				
			<v-row
				v-for="answer in question.answers"
				:key="answer.id"
				class="align-end">
				<v-col cols="auto">
					<v-checkbox
						v-model="answer.is_correct"
						hide-details="auto"
						@change="debUpdateQuestion">
					</v-checkbox>
				</v-col>
				<v-col>
					<v-textarea
						v-model="answer.text"
						hide-details="auto"
						rows="1"
						auto-grow
						@input="debUpdateQuestion">
					</v-textarea>
				</v-col>
				<v-col
					cols="auto"
					class="pb-0">
					<v-btn
						icon
						@click="deleteAnswer(answer)">
						<v-icon>
							mdi-delete
						</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<v-row
				align="baseline"
				class="mt-5">
				<v-col cols="auto">
					<v-tooltip right>
						<template #activator="{ on }">
							<v-btn
								v-on="on"
								small
								icon
								@click="addAnswer">
								<v-icon>
									mdi-plus
								</v-icon>
							</v-btn>
						</template>
						<span>Додати відповідь</span>
					</v-tooltip>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="auto">
					<v-tooltip top>
						<template #activator="{ on }">
							<v-btn
								v-on="on"
								color="error"
								small
								icon
								@click="deleteQuestion">
								<v-icon>
									mdi-delete
								</v-icon>
							</v-btn>
						</template>
						<span>Видалити питання</span>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from 'lodash'

import Question from '../../models/Question'
import Answer from '../../models/Answer'

import ImageCard from '../ImageCard.vue'
import FileUpload from '../FileUpload.vue'

export default {
	components: {
		ImageCard,
		FileUpload,
	},
	props: {
		question: {
			type: Question,
			required: true,
		},
	},
	data: () => ({
		loading: false,
		showUploadZone: false,
	}),
	computed: {
		images() {
			return this.question?.images || []
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async updateQuestion() {
			try {
				this.loading = true
				await Question.fetchCreateOrUpdate(this.question)
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити запитання',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		debUpdateQuestion: debounce(function() {
			this.updateQuestion()
		}, 1000),

		addAnswer() {
			this.question.addAnswer()
		},

		async deleteQuestion() {
			try {
				this.loading = true
				const confirm = await this.$swal('confirm', {
					title: 'Дійсно хочеш видалити це запитання?',
					buttonCancel: {
						text: 'Ні',
					},
					buttonOk: {
						text: 'Так',
					},
				})

				if (confirm) {
					await Question.fetchDelete(this.question?.id)
				}
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			} finally {
				this.loading = false
			}
		},

		async deleteAnswer(answer) {
			try {
				this.loading = true
				const confirm = await this.$swal('confirm', {
					title: 'Дійсно видалити відповідь?',
					buttonCancel: {
						text: 'Ні',
					},
					buttonOk: {
						text: 'Так',
					},
				})

				if (confirm) {
					await Answer.fetchDelete(answer?.id)
					await Question.fetchGet(this.question?.id)
				}
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			} finally {
				this.loading = false
			}
		},

		/**
		 * Add image media to question
		 */
		async addImage(file = File) {
			try {
				const { id } = this.question

				await Question.createImage(id, file)
				await Question.fetchGet(this.question?.id)

				this.notify({
					text: 'Картинку додано',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			}
		},

		/**
		 * Delete attached image media from question
		 */
		// eslint-disable-next-line max-statements
		async deleteImage({ id: imageId }) {
			try {
				this.loading = true
				const confirm = await this.$swal('confirm', {
					title: 'Дійсно видалити зображення?',
					buttonCancel: {
						text: 'Ні',
					},
					buttonOk: {
						text: 'Так',
					},
				})

				if (confirm) {
					const { id } = this.question
					await Question.deleteImage(id, imageId)
					await Question.fetchGet(this.question?.id)

					this.notify({
						text: 'Картинку видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.$swal('error', {
					title: 'Помилка',
				})
				throw error
			} finally {
				this.loading = false
			}
		},
	},
	watch: {
		'question.order': 'updateQuestion',
	},
}
</script>
