<template>
	<div>
		<v-sheet
			class="pa-10 text-center primary--text cursor-pointer !w-bg-gray-500/20 !w-border-dashed"
			outlined
			v-cloak
			rounded
			@drop.prevent="handleDropFile"
			@dragover.prevent
			@click="handleDropZoneClick">
			<v-icon
				x-large
				color="primary"
				class="ma-4">
				mdi-file-download-outline
			</v-icon>
			<p>Закинь файл</p>
		</v-sheet>
		<div class="d-flex align-center">
			<v-file-input
				ref="fileInput"
				v-model="file"
				chips
				:label="label"
				:loading="loading"
				v-bind="attributes"></v-file-input>
			<v-btn
				small
				elevation="0"
				color="primary"
				class="ml-4 text-none"
				:disabled="disabled || loading"
				:loading="loading"
				@click="handleInput">
				<v-icon left>mdi-file-upload</v-icon>
				Залити файл
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: [ Array, Object, String, Number ],
			required: false,
		},
		label: {
			type: String,
			default: 'Обрати файл',
		},
		multiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data: () => ({
		file: null,
	}),
	computed: {
		defaultValue() {
			return this.value || null
		},
		multipleAttr() {
			return this.multiple ? 'multiple' : ''
		},
		attributes() {
			return this.multiple
				? { multiple: 'multiple' }
				: {}
		},
		disabled() {
			return this.multiple
				? this.file && !this.file.length > 0
				: !this.file
		},
	},
	methods: {
		handleInput() {
			this.$emit('upload', this.file)
		},
		handleDropFile(e) {
			[this.file] = e.dataTransfer.files
		},
		handleDropZoneClick() {
			this.$refs?.fileInput?.$refs?.['input']?.click()
		},
		handleFilePaste(e) {
			[this.file] = e.clipboardData.files
		},
	},
	mounted() {
		window.addEventListener('paste', this.handleFilePaste)
	},
	beforeDestroy() {
		window.removeEventListener('paste', this.handleFilePaste)
	},
}
</script>
