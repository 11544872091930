<template>
	<v-container>
		<portal to="app-bar-title">
			{{ test.name }}
		</portal>

		<v-card
			:elevation="24"
			class="mb-4"
			color="amber lighten-1"
			:loading="loading && 'black'"
			outlined
			light>
			<v-card-title>
				<v-text-field
					v-model="test.name"
					label="Назва тесту"
					@input="debUpdateTest">
				</v-text-field>
			</v-card-title>
			<v-card-text>
				<v-chip small dark>
					{{ questionsCount }}
				</v-chip> запитань
				<br>
				<v-row class="align-center mt-3">
					<v-col>
						<v-file-input
							v-model="file"
							label="Імпортувати запитання"
							hide-details
							small-chips
							show-size
							outlined
							chips
							dense>
						</v-file-input>
					</v-col>
					<v-col cols="auto">
						<v-btn
							@click="importTest">
							Залити
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<draggable
			:value="test.questions"
			handle=".draggable-handle"
			@input="updateQuestionsOrder">
			<QuestionCard
				v-for="question in test.questions"
				:key="question.id"
				:question="question"
				class="my-12">
			</QuestionCard>
		</draggable>

		<v-tooltip right>
			<template #activator="{ on }">
				<v-btn
					v-on="on"
					icon
					@click="addQuestion">
					<v-icon>
						mdi-plus
					</v-icon>
				</v-btn>
			</template>
			<span>Додати питання</span>
		</v-tooltip>

	</v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { debounce } from 'lodash'

import draggable from 'vuedraggable'
import QuestionCard from '../components/Tests/QuestionCard'

import Test from '../models/Test'

export default {
	components: {
		draggable,
		QuestionCard,
	},
	props: {
		id: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		loading: false,
		file: null,
	}),
	computed: {
		test() {
			return Test.query()
				.with('questions', (q) => q.orderBy('order'))
				.find(this.id) || {}
		},
		questionsCount() {
			return this.test?.questions?.length || 0
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadTest(id) {
			try {
				this.loading = true
				await Test.fetchGet(id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити тест',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		async updateTest() {
			try {
				this.loading = true
				await Test.fetchUpdate(this.test)
			} catch (error) {
				this.notify({
					text: 'Не вдалося оновити тест',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		async importTest() {
			try {
				this.loading = true
				await Test.import(this.id, this.file)
				this.notify({
					text: 'Тест імпортовано',
					color: 'success',
				}, { root: true })
				this.loadTest(this.id)
			} catch (error) {
				this.notify({
					text: 'Не вдалося імпортувати тест',
					color: 'error',
				}, { root: true })
				throw error
			} finally {
				this.loading = false
			}
		},

		debUpdateTest: debounce(function() {
			this.updateTest()
		}, 1000),

		updateQuestionsOrder(questions) {
			questions = questions.map((question, index) => ({
				...question,
				order: index + 1,
			}))

			const test = {
				...this.test,
				questions,
			}

			Test.insert({ data: test })
		},

		addQuestion() {
			return this.test?.addQuestion()
		},
	},
	watch: {
		id: {
			immediate: true,
			handler(id) {
				this.loadTest(id)
			},
		},
	},
}
</script>
