<template>
	<v-sheet
		class="w-relative w-w-max">
		<v-img
			:src="image.original_url"
			class="my-2"
			max-width="300"
			max-height="200"
			contain>
		</v-img>

		<v-speed-dial
			class="w-absolute"
			direction="left"
			right
			top>
			<template #activator>
				<v-btn
					small
					fab>
					<v-icon v-if="fab">
						mdi-close
					</v-icon>
					<v-icon v-else>
						mdi-dots-vertical
					</v-icon>
				</v-btn>
			</template>
			<v-btn
				color="error"
				x-small
				fab
				@click="$emit('delete', image)">
				<v-icon>mdi-delete</v-icon>
			</v-btn>
		</v-speed-dial>
	</v-sheet>
</template>

<script>
export default {
	props: {
		image: {
			type: Object,
			required: true,
		},
	},
}
</script>
